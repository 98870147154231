import { useState } from "react"
import { Button, FormControl, InputGroup } from "react-bootstrap";
import { toast } from "react-toastify";
import { loginToServer } from "./dataService";

export function LoginComponent({ pwHash, setPwHash }: { pwHash: string | undefined, setPwHash: (pw: string | undefined) => void }) {
  const [pw, setPw] = useState("");

  const handleLogin = () => {
    digestMessage(pw)
      .then(hash => {
        // TODO: check if correct
        loginToServer(hash)
          .then(successful => {
            if (successful) {
              setPwHash(hash);
            } else {
              toast.error("Falsches Passwort");
            }
          });
      });
  }

  return <>
    {pwHash && <>
      <Button variant="secondary" onClick={() => setPwHash(undefined)}>Logout</Button>
    </>}
    {!pwHash && <>
      <InputGroup>
        <FormControl type="password"
          placeholder="Passwort"
          value={pw} onChange={(ev) => setPw(ev.currentTarget.value)}
          onKeyDown={(ev) => ev.key === "Enter" &&handleLogin()} />
        <Button variant="success" onClick={handleLogin}>Login</Button>
      </InputGroup>

    </>
    }
  </>
}

// ~https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest
async function digestMessage(message: string): Promise<string> {
  const msgUint8 = new TextEncoder().encode(message);                           // encode as (utf-8) Uint8Array
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);           // hash the message
  const hashArray = Array.from(new Uint8Array(hashBuffer));                     // convert buffer to byte array
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
  return hashHex;
}