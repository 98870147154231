import { Col, Row } from "react-bootstrap";
import { Collections } from "../../the-predictor-shared/types";
import { priceToString } from "./utils";

export function SortStatisticSmallestMaxOption({ collections, setContractFilter }:
  { collections: Collections, setContractFilter: (f: string) => void }) {

  const sortedList = Object.entries(collections.contractsets)
    .map(([csID, cs]) => {
      const sortedOptions = Object.values(collections.contracts)
        .filter(c => c.set_id === csID)
        .sort((cA, cB) => cB.price - cA.price); // sort highest to front
      return {
        id: csID,
        title: cs.i18n.de.title,
        options: sortedOptions
      }
    })
    .filter(cs => cs.options.length > 0)
    .sort((csA, csB) => csA.options[0].price - csB.options[0].price); // sort smallest to top

  return (
    <>
      {sortedList.map(el => (
        <Row className="my-1">
          <Col
          onClick={() => setContractFilter(el.title)}
            style={{ cursor: "pointer" }}>
            {el.title}
          </Col>
          {el.options.map((c, i) => (
            <>
              <Col md="auto" style={{background: `linear-gradient(to top, #575757 0%, #575757 ${c.price}%, transparent ${c.price}%)`}}>
                {priceToString(c.price).padStart(5, '\u00A0')}
                {i + 1 < el.options.length && <>,</>}
              </Col>
            </>
          ))}
        </Row>
      ))}
    </>
  )
}