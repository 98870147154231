import { Col, Row } from "react-bootstrap";
import { Collections } from "../../the-predictor-shared/types";
import { priceToString } from "./utils";

export function PortfolioOfUser({ collections, userId, setContractFilter }: { collections: Collections, userId: string, setContractFilter: (cTitle: string) => void }) {

  const selectedPortfolio = userId && collections.users[userId] && collections.users[userId].profile &&
    Object.keys(collections.users[userId].profile.portfolio)
      .filter(stockKey => collections.users[userId].profile.portfolio[stockKey] !== 0)
      .map(stockKey => ({ amount: collections.users[userId].profile.portfolio[stockKey], stockId: stockKey }));

  return <>
    {!selectedPortfolio && <p>Kein Portfolio für {collections.users[userId].username || userId} vorhanden.</p>}
    {selectedPortfolio &&
      <>
      <h3>Portfolio von {collections.users[userId].username}</h3>
      <p>Cash: {priceToString(collections.users[userId].profile.cash[process.env.REACT_APP_MARKET_ID as string])}/{priceToString(collections.users[userId].profile.total_cash)}</p>
        {selectedPortfolio.length === 0 && <p>Click on a user on the left</p>}
        {selectedPortfolio.length > 0 &&
          selectedPortfolio.map(({ amount, stockId }) => (
            <Row key={stockId}
              onClick={() => setContractFilter(collections.contractsets[collections.contracts[stockId]?.set_id]?.i18n.de.title)}
              style={{ cursor: "pointer" }}>
              <Col lg="1">{amount}</Col>
              <Col>{collections.contracts[stockId]?.i18n.de.title}</Col>
              <Col className='text-end' lg="2">{Math.round((collections.contracts[stockId]?.price || 0) * 100) / 100}</Col>
              {/*<Col>{JSON.stringify(collections.contracts[stockId])}</Col>*/}
            </Row>
          ))
        }
      </>
    }
  </>;
}