import { Collections } from "../../the-predictor-shared/types";

export async function getLastFetchedCollections(pwHash: string): Promise<Collections|null> {
  const collections: Collections = await fetch(`${process.env.REACT_APP_BACKEND_URL}/ws/lastFetchedCollections`, {
    headers: {
      "PWHASH": pwHash
    }
  })
    .then(resp => resp.json());
  (window as any).collections = collections;
  return collections;
}

export async function forceRefetch(pwHash: string): Promise<Collections> {
  const collections: Collections = await fetch(`${process.env.REACT_APP_BACKEND_URL}/ws/forceRefetch`, {
    headers: {
      "PWHASH": pwHash
    }
  })
    .then(resp => resp.json());
  (window as any).collections = collections;
  return collections;
}

export function loginToServer(pwHash: string): Promise<boolean> {
  return fetch(`${process.env.REACT_APP_BACKEND_URL}/`, {
    headers: {
      "PWHASH": pwHash
    }
  })
    .then(res => res.status === 200);
}