import { useState } from "react";
import { Collections } from "../../the-predictor-shared/types";
import { SortStatisticMostMatching } from "./SortStatisticMostMatching";
import { SortStatisticSmallestMaxOption } from "./SortStatisticSmallestMaxOption";
import { SortStatisticAmountPerCS } from "./SortStatisticUserPerCS";

enum STAT_TYPES {
  NOTHING = "",
  AMOUNT_PER_CS = "minAmountPerContractset",
  MOST_MATCHING = "mostMatchingContractset",
  SMALLEST_MAX_OPTION = "smallestMaxOption",
};

export function SortStatistic({ collections, setContractFilter, selectUserId }:
  { collections: Collections, setContractFilter: (f: string) => void, selectUserId: (f: string) => void }) {
  const [selectedStatistic, setSelectedStatistic] = useState<STAT_TYPES>(STAT_TYPES.NOTHING);

  return <>
    <h3>Sortieren</h3>
    <select
      className="form-control"
      value={selectedStatistic}
      onChange={ev => setSelectedStatistic(ev.currentTarget.value as STAT_TYPES)}>
      <option value={STAT_TYPES.NOTHING}>Keine</option>
      {collections.ts && <>
        <option value={STAT_TYPES.AMOUNT_PER_CS}>Gekaufte Anzahl pro Contractset</option>
        <option value={STAT_TYPES.MOST_MATCHING}>Meiste Übereinstimmung der Contractset</option>
        <option value={STAT_TYPES.SMALLEST_MAX_OPTION}>Kleinster maximaler Contract</option>
        </>}
    </select>
    {selectedStatistic === STAT_TYPES.AMOUNT_PER_CS &&
      <SortStatisticAmountPerCS collections={collections}
        setContractFilter={setContractFilter}
      ></SortStatisticAmountPerCS>
    }
    {selectedStatistic === STAT_TYPES.MOST_MATCHING &&
      <SortStatisticMostMatching collections={collections}
        setContractFilter={setContractFilter} selectUserId={selectUserId}
      ></SortStatisticMostMatching>
    }
    {selectedStatistic === STAT_TYPES.SMALLEST_MAX_OPTION &&
      <SortStatisticSmallestMaxOption collections={collections}
        setContractFilter={setContractFilter}
      ></SortStatisticSmallestMaxOption>
    }
  </>;
}