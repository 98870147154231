import clsx from "clsx";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Collections } from "../../the-predictor-shared/types";

enum SORT_TYPE {
  ABC, POSITIVE, NEGATIVE, SUM
};
enum SORT_ORDER {
  ASC = 1, DESC = -1
};

export function SortStatisticAmountPerCS({ collections, setContractFilter }: { collections: Collections, setContractFilter: (f: string) => void }) {
  const [sortType, setSortType] = useState(SORT_TYPE.SUM)
  const [sortOrder, setSortOrder] = useState(SORT_ORDER.ASC)

  const sumPerId: { [k: string]: { positive: number, negative: number } } = {};
  Object.values(collections.users)
    .forEach(user => {
      if (user.profile?.portfolio) {
        Object.entries(user.profile.portfolio)
          .forEach(([cId, amountFromUser]) => {
            if (Object.keys(collections.contracts).includes(cId)) {
              const setId = collections.contracts[cId].set_id;
              if (!Object.keys(sumPerId).includes(setId)) {
                sumPerId[setId] = { positive: 0, negative: 0 };
              }
              if (amountFromUser >= 0) {
                sumPerId[setId].positive += amountFromUser;
              } else {
                sumPerId[setId].negative += amountFromUser;
              }
            }
          })
      }
    });
  const csList = Object.entries(collections.contractsets).map(([csIds, csValues]) => ({
    id: csIds,
    contractSet: csValues as any,
    positive: sumPerId[csIds]?.positive,
    negative: sumPerId[csIds]?.negative,
  }));

  const getHandleHeaderClicked = (_sortType: SORT_TYPE) => {
    return () => {
      if (sortType === _sortType) {
        setSortOrder(oldOrder => -1 * oldOrder);
      } else {
        setSortOrder(SORT_ORDER.ASC);
        setSortType(_sortType);
      }
    };
  }

  return <>
    <Row>
      <Col className={clsx("user-select-none", "font-weight-bold", { "text-decoration-underline": sortType === SORT_TYPE.ABC })}
        style={{ cursor: "pointer" }}
        onClick={getHandleHeaderClicked(SORT_TYPE.ABC)}>Contractset</Col>
      <Col className={clsx("text-end", "user-select-none", "font-weight-bold", { "text-decoration-underline": sortType === SORT_TYPE.POSITIVE })}
        md="1"
        style={{ cursor: "pointer" }}
        onClick={getHandleHeaderClicked(SORT_TYPE.POSITIVE)}>Positiv</Col>
      <Col className={clsx("text-end", "user-select-none", "font-weight-bold", { "text-decoration-underline": sortType === SORT_TYPE.NEGATIVE })}
        md="1"
        style={{ cursor: "pointer" }}
        onClick={getHandleHeaderClicked(SORT_TYPE.NEGATIVE)}>Negativ</Col>
      <Col className={clsx("text-end", "user-select-none", "font-weight-bold", { "text-decoration-underline": sortType === SORT_TYPE.SUM })}
        md="1"
        style={{ cursor: "pointer" }}
        onClick={getHandleHeaderClicked(SORT_TYPE.SUM)}>Summe</Col>
    </Row>
    {csList
      .sort((a, b) => ({
        [SORT_TYPE.ABC]: a.contractSet.i18n.de.title.localeCompare(b.contractSet.i18n.de.title),
        [SORT_TYPE.POSITIVE]: b.positive - a.positive,
        [SORT_TYPE.NEGATIVE]: a.negative - b.negative,
        [SORT_TYPE.SUM]: (b.positive - b.negative) - (a.positive - a.negative),
      }[sortType] * sortOrder
      ))
      .map(el => (
        <Row>
          <Col onClick={() => setContractFilter(el.contractSet.i18n.de.title)} style={{ cursor: "pointer" }}>{el.contractSet.i18n.de.title}</Col>
          <Col className="text-end" md="1">{el.positive}</Col>
          <Col className="text-end" md="1">{el.negative}</Col>
          <Col className="text-end" md="1">{el.positive - el.negative}</Col>
        </Row>
      ))}
  </>;
}