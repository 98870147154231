import { Col, Row } from "react-bootstrap";
import { UserList } from "../../the-predictor-shared/types";
import { priceToString } from "./utils";

export function FindContract({ users, contracts, contractsets, selectedUserId, setSelectedUserId, filter, setFilter }:
  { users: UserList, contracts: any, contractsets: any, selectedUserId: string | null, setSelectedUserId: (uid: string) => void, filter: string, setFilter: (f: string) => void }) {

  if (users == null || contracts == null || contractsets == null) {
    return <p>Data not loaded</p>;
  } else {

    const filteredContractSetsByContractsets = Object.entries(contractsets)
      .filter(([key, value]: [key: string, value: any]) => key.includes(filter) || value.i18n.de.title.toLowerCase().includes(filter.toLowerCase()) || value.title.includes(filter.toLowerCase()))
      .map(([csKey, value]: [csKey: string, value: any]) => (
        {
          set: { ...value, id: csKey },
          highlight: true,
          contracts: Object.entries(contracts)
            .filter(([cId, cVal]: [k: string, v: any]) => cVal.set_id === csKey)
            .map(([cId, cVal]: [k: string, v: any]) => ({ ...cVal, id: cId, highlight: false }))
        }
      ));
    const filteredContractSetsByContracts = Object.entries(contracts)
      .filter(([key, value]: [key: string, value: any]) => key.includes(filter) || value.i18n.de.title.toLowerCase().includes(filter.toLowerCase()) || value.title.toLowerCase().includes(filter.toLowerCase()))
      .map(([key, value]: [key: string, value: any]) => (
        {
          set: { ...contractsets[value.set_id], id: value.set_id },
          highlight: false,
          contracts: Object.entries(contracts)
            .filter(([cId, cVal]: [k: string, v: any]) => cVal.set_id === value.set_id)
            .map(([cId, cVal]: [k: string, v: any]) => ({ ...cVal, id: cId, highlight: cId === key }))
        }
      ));

    let filteredContractSets = [...filteredContractSetsByContractsets, ...filteredContractSetsByContracts];

    // each set only once
    filteredContractSets = filteredContractSets.filter(({ set: outerSet }, i) => filteredContractSets.findIndex(({ set: innerSet }) => innerSet.id === outerSet.id) === i)

    return <>
      <input className="form-control mb-3" type="text" value={filter} onChange={(ev) => setFilter(ev.currentTarget.value)} />
      {filter.length <= 0 && <p>Search for a contract(set)</p>}
      {filter.length > 0 && filteredContractSets.length > 0 &&
        filteredContractSets.map(cs => (
          <>
            <Row className={cs.highlight ? "highlightContract" : ""}><Col><h4>{cs.set.i18n.de.title}</h4></Col></Row>
            {cs.contracts.map(c => {
              const userInvestedInContract = Object.entries(users)
                .filter(([userId, u]) => u.profile && u.profile.portfolio && Object.keys(u.profile.portfolio).includes(c.id) && u.profile.portfolio[c.id] !== 0);
              return (
                <>
                  <Row className={c.highlight ? "highlightContract" : ""}>
                    <Col><b>{c.i18n.de.title}</b> (
                      {userInvestedInContract.filter(([uId, u]) => u.profile.portfolio[c.id] > 0).reduce((s, [uId, u]) => s + u.profile.portfolio[c.id], 0)}{' | '}
                      {userInvestedInContract.filter(([uId, u]) => u.profile.portfolio[c.id] < 0).reduce((s, [uId, u]) => s + u.profile.portfolio[c.id], 0)}
                      )</Col>
                    <Col md="auto">{priceToString(c.price)}</Col>
                  </Row>
                  <Row>
                    <Col className="ms-3">
                      {userInvestedInContract
                        .map(([uId, u], i) => {
                          if (uId === selectedUserId) {
                            return (<>
                              <span onClick={() => setSelectedUserId(uId)} style={{cursor: "pointer"}}><b>{u.username} ({u.profile.portfolio[c.id]})</b></span>
                              {i !== userInvestedInContract.length && ", "}
                            </>);
                          } else {
                            return (<>
                              <span onClick={() => setSelectedUserId(uId)} style={{cursor: "pointer"}}>{u.username} ({u.profile.portfolio[c.id]})</span>
                              {i !== userInvestedInContract.length - 1 && ", "}
                            </>);
                          }
                        })}
                    </Col>
                  </Row>
                </>
              );
            })}
          </>
        ))
      }
    </>;
  }
}