import React, { useCallback, useState } from 'react';
import { Button, Col, Container, Nav, NavItem, NavLink, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { CustomMonaco } from './CustomMonaco';
import { forceRefetch as forceFetch, getLastFetchedCollections } from './dataService';
import { FindContract } from './FindContract';
import { FindUser } from './FindUser';
import { PortfolioOfUser } from './PortfolioOfUser';
import { Collections } from '../../the-predictor-shared/types';
import { dateToString } from './utils';
import { SortStatistic } from './SortStatistic';
import { LoginComponent } from './LoginComponent';
import { CollapsableRowWrapper } from './components/CollapsableRowWrapper';

function App() {
  const [pwHash, setPwHash] = useState<string | undefined>();
  const [collections, setCollections] = useState<Collections>([{ "replace": "insert json object here" }] as any);
  const [lastRefreshTs, setLastRefreshTs] = useState<any>(new Date().getTime());
  const [selectedChartTab, setSelectedChartTab] = useState<string>("all");
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [contractFilter, setContractFilter] = useState("");

  const reloadIframe = useCallback(() => setLastRefreshTs(new Date().getTime()), [setLastRefreshTs]);

  const handleLoadLatestData = () => {
    if (pwHash) {
      getLastFetchedCollections(pwHash).then(collections => {
        if (collections) {
          setCollections(collections);
          toast.success("Daten geladen");
        } else {
          toast.error("Daten wurden nicht geladen.")
        }
      });
    } else {
      toast.error("Bitte einloggen");
    }
  }

  const handleForceFetch = () => {
    if (pwHash) {
      forceFetch(pwHash).then((collections) => {
        setCollections(collections);
        reloadIframe();
        toast.success(`Abruf ausgeführt`);
      })
    } else {
      toast.error("Bitte einloggen");
    }
  }

  return (
    <Container>
      <Row className='my-3' style={{ alignItems: "center" }}>
        <Col md="auto">
          <img src="/logo_on_dark_compact.svg" className='header-logo' alt='Logo von "The Predictor"' />
        </Col>
        <Col md="auto">
          <Button onClick={handleLoadLatestData} disabled={pwHash == null}>Aktuelle Daten herunterladen</Button>
        </Col>
        <Col md="auto">
          <Button onClick={handleForceFetch} disabled={pwHash == null} variant="danger">Abrufen jetzt erzwingen</Button>
        </Col>
        <Col md="auto">
          {collections.ts && <>Zuletzt geladen: {dateToString(collections.ts)}</>}
          {!collections.ts && <>Daten noch nicht geladen</>}
        </Col>
        <Col className='text-end'>
          <LoginComponent pwHash={pwHash} setPwHash={setPwHash}></LoginComponent>
        </Col>
      </Row>
      <Row>
        <Col>
          <Nav variant="pills" onSelect={(selectedKey) => setSelectedChartTab(selectedKey || "all")} activeKey={selectedChartTab}>
            <NavItem>
              <NavLink eventKey={"all"}>Gesamter Zeitraum</NavLink>
            </NavItem>
            <NavItem>
              <NavLink eventKey={"36h"}>Letzten 36h</NavLink>
            </NavItem>
          </Nav>
        </Col>
        <Col className='text-end'>
          <Button onClick={reloadIframe}>Diagramm neuladen</Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <iframe
            style={{ height: "60vh", width: "100%", display: selectedChartTab === "all" ? "block" : "none" }}
            title='Leaderboard-Chart'
            src={`https://charts.mongodb.com/charts-misc-spuln/embed/charts?id=63dc3616-3346-4c6a-8fda-c583c44da05b&maxDataAge=30&theme=dark&autoRefresh=false&reload_ts=${lastRefreshTs}`}
          ></iframe>
          <iframe
            style={{ height: "60vh", width: "100%", display: selectedChartTab === "36h" ? "block" : "none" }}
            title='Leaderboard-Chart last 36h'
            src={`https://charts.mongodb.com/charts-misc-spuln/embed/charts?id=bf1967df-2f36-4085-a9a1-74248b3c4c3c&maxDataAge=30&theme=dark&autoRefresh=false&reload_ts=${lastRefreshTs}`}>
          </iframe>
        </Col>
      </Row>
      <CollapsableRowWrapper>
        <Row>
          <Col>
            <Row>
              <Col>
                <h3>Nutzer suchen</h3>
                <FindUser users={collections.users} setSelectedUserId={setSelectedUserId}></FindUser>
              </Col>
            </Row>
            {selectedUserId != null &&
              <Row>
                <Col>
                  <PortfolioOfUser userId={selectedUserId} collections={collections} setContractFilter={setContractFilter} />
                </Col>
              </Row>
            }
          </Col>
          <Col>
            <h3>Contract(set) suchen</h3>
            <FindContract users={collections.users} contractsets={collections.contractsets} contracts={collections.contracts}
              selectedUserId={selectedUserId} setSelectedUserId={setSelectedUserId} filter={contractFilter} setFilter={setContractFilter}></FindContract>
          </Col>
        </Row>
      </CollapsableRowWrapper>
      <Row>
        <Col>
          <SortStatistic collections={collections} setContractFilter={setContractFilter} selectUserId={setSelectedUserId}></SortStatistic>
        </Col>
      </Row>
      {false &&
        <Row className='mt-3'>
          <Col>
            <CustomMonaco
              myDefaultValue={JSON.stringify(collections, null, 2)}
            />
          </Col>
        </Row>
      }
      <Row style={{ height: "80vH" }}></Row>
    </Container>
  );
}

export default App;
