import clsx from "clsx";
import React, { ReactElement, useState } from "react";

export function CollapsableRowWrapper({ children }: { children: ReactElement }) {
  const [quickSearchCollapsed, setQuickSearchCollapsed] = useState(false);

  return (
    <div className={clsx('collapsable', { 'collapsed': quickSearchCollapsed })}>
      <div className='collapse-indicator' onClick={() => setQuickSearchCollapsed(b => !b)}>
        {quickSearchCollapsed ? <>&#x25B6;</> : <>&#x25BC;</>}
      </div>
      <div className="collapse-content">
        {children}
      </div>
    </div>
  )
}