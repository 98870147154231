export function dateToString(_date: Date | number): string {
  let date: Date = new Date();
  if (typeof (_date) === `number`) {
    date.setTime(_date);
  } else {
    date = _date
  }
  return new Intl.DateTimeFormat("de-DE", { timeStyle: "medium", dateStyle: "short" }).format(date);
}

export function priceToString(price: number): string {
  return (Math.round(price * 100) / 100 || 0).toFixed(2);
}