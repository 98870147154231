import clsx from "clsx";
import { useState } from "react";
import { Col, FormControl, Row } from "react-bootstrap";
import { Collections, User } from "../../the-predictor-shared/types";

enum SORT_TYPE {
  ABC, POSITIVE, NEGATIVE, SUM
};
enum SORT_ORDER {
  ASC = 1, DESC = -1
};

export function SortStatisticMostMatching({ collections, setContractFilter, selectUserId }:
  { collections: Collections, setContractFilter: (f: string) => void, selectUserId: (f: string) => void }) {
  const [strUsernames, setStrUsernames] = useState("")
  const [sortType, setSortType] = useState(SORT_TYPE.SUM)
  const [sortOrder, setSortOrder] = useState(SORT_ORDER.ASC)

  const sumPerId: { [setId: string]: { [username: string]: { positive: number, negative: number } } } = {};
  let userList: [id: string, user: User][] = [];

  strUsernames.split(",")
    .map(username => username.toLowerCase().trim())
    .filter(username => username.length > 0)
    .forEach(filteredUsername => {
      Object.entries(collections.users)
        .forEach(([uID, user]) => {
          if (user.profile?.portfolio
            && user.username.toLowerCase().includes(filteredUsername)) {
            if (!userList.find((el) => el[0] === uID)) userList.push([uID, user]);
          }
        })
    });
  userList.forEach(([uID, user]) => {
    Object.entries(user.profile.portfolio)
      .forEach(([cId, amountFromUser]) => {
        if (Object.keys(collections.contracts).includes(cId)) {
          const setId = collections.contracts[cId].set_id;
          if (!Object.keys(sumPerId).includes(setId)) {
            sumPerId[setId] = {};
            userList.forEach(([uID,]) => {
              sumPerId[setId][uID] = { positive: 0, negative: 0 };
            })
          }
          if (amountFromUser >= 0) {
            sumPerId[setId][uID].positive += amountFromUser;
          } else {
            sumPerId[setId][uID].negative += amountFromUser;
          }
        }
      })
  });

  const csList = Object.entries(collections.contractsets)
    .filter(([csId,]) => Object.keys(sumPerId).includes(csId))
    .map(([csIds, csValues]) => ({
      id: csIds,
      contractSet: csValues as any,
      positive: Object.values(sumPerId[csIds]).reduce((n, a) => n + a.positive, 0),
      negative: Object.values(sumPerId[csIds]).reduce((n, a) => n + a.negative, 0),
    }));

  const getHandleHeaderClicked = (_sortType: SORT_TYPE) => {
    return () => {
      if (sortType === _sortType) {
        setSortOrder(oldOrder => -1 * oldOrder);
      } else {
        setSortOrder(SORT_ORDER.ASC);
        setSortType(_sortType);
      }
    };
  }

  return <>
    <Row>
      <Col>
        <FormControl
          className="my-2"
          placeholder="Benutzernamen durch Komma getrennt"
          value={strUsernames}
          onChange={(ev) => setStrUsernames(ev.currentTarget.value)} />
      </Col>
    </Row>
    <div className="table-responsive">
      <table className="table">
        <thead>
          <tr>
            <th className={clsx("user-select-none", "font-weight-bold", { "text-decoration-underline": sortType === SORT_TYPE.ABC })}
              style={{ cursor: "pointer" }}
              onClick={getHandleHeaderClicked(SORT_TYPE.ABC)}>Contractset</th>
            {userList
              .map(([uId, user]) => (
                <th key={`${uId}`}
                  className={clsx("text-center", "user-select-none", "text-truncate", "font-weight-bold", { "text-decoration-underline": sortType === SORT_TYPE.ABC })}
                  style={{ cursor: "pointer", maxWidth: "6em" }}
                  onClick={() => selectUserId(uId)}>{user.username}</th>
              ))}
            <th className={clsx("text-center", "user-select-none", "font-weight-bold", { "text-decoration-underline": sortType === SORT_TYPE.POSITIVE })}
              style={{ cursor: "pointer" }}
              onClick={getHandleHeaderClicked(SORT_TYPE.POSITIVE)}>Pos | Neg</th>
            <th className={clsx("text-end", "user-select-none", "font-weight-bold", { "text-decoration-underline": sortType === SORT_TYPE.SUM })}
              style={{ cursor: "pointer" }}
              onClick={getHandleHeaderClicked(SORT_TYPE.SUM)}>Summe</th>
          </tr>
        </thead>
        <tbody>
        {csList
          .sort((a, b) => ({
            [SORT_TYPE.ABC]: a.contractSet.i18n.de.title.localeCompare(b.contractSet.i18n.de.title),
            [SORT_TYPE.POSITIVE]: b.positive - a.positive,
            [SORT_TYPE.NEGATIVE]: a.negative - b.negative,
            [SORT_TYPE.SUM]: (b.positive - b.negative) - (a.positive - a.negative),
          }[sortType] * sortOrder
          ))
          .map(el => (
            <tr>
              <td onClick={() => setContractFilter(el.contractSet.i18n.de.title)} style={{ cursor: "pointer" }}>{el.contractSet.i18n.de.title}</td>
              {userList
              .map(([uId, user]) => (
                <td key={`${el.id}_${uId}`} className="text-center font-monospace text-nowrap">
                  {String(sumPerId[el.id][uId].positive).padStart(4, '\u00A0')} | {String(sumPerId[el.id][uId].negative).padEnd(4, '\u00A0')}
                </td>
                ))}
              <td className="text-center font-monospace text-nowrap">{String(el.positive).padStart(4, '\u00A0')} | {String(el.negative).padEnd(4, '\u00A0')}</td>
              <td className="text-end">{el.positive - el.negative}</td>
            </tr>
          ))}
          </tbody>
      </table>
    </div>
  </>;
}