import { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { UserList } from "../../the-predictor-shared/types";
import { priceToString } from "./utils";

export function FindUser({ users, setSelectedUserId }: { users: UserList, setSelectedUserId: (userId: string) => void }) {
  const [filter, setFilter] = useState("");

  const filteredUsers = useMemo(() => users && Object.keys(users)
    .map((userId: string) => ({ userId: userId, user: users[userId] }))
    .filter(({ userId, user }) => user.username.toLowerCase().includes(filter.toLowerCase()))
    , [users, filter]);

  const handleSelectPortfolio = useCallback((userId: string) => {
    setSelectedUserId(userId);
  }, [setSelectedUserId]);

  useEffect(() => {
    if (filteredUsers) {
      if (filteredUsers.length === 1) {
        handleSelectPortfolio(filteredUsers[0].userId);
      } else {
        const exactMatch = filteredUsers.filter(({ user }) => user.username.toLowerCase() === filter.toLowerCase());
        if (exactMatch.length > 0) {
          handleSelectPortfolio(exactMatch[0].userId);
        }
      }
    }
  }, [filter, filteredUsers, handleSelectPortfolio]);

  if (users == null) {
    return <p>Daten nocht nicht geladen</p>;

  } else {
    return <>
      <input className="form-control" type="text" value={filter} onChange={(ev) => setFilter(ev.currentTarget.value)} />
      {filter.length <= 0 && <p>Search for a user</p>}
      {filter.length > 0 && <>
        <Row>
          <Col>Username</Col>
          <Col>Cash</Col>
          <Col>Total cash (incl. stock)</Col>
        </Row>
        <hr className="my-0" />
      </>}
      {filter.length > 0 && filteredUsers &&
        filteredUsers
          .map(({ userId, user }) => <Row key={user.username} onClick={() => handleSelectPortfolio(userId)} style={{ cursor: "pointer" }}>
            <Col className="text-truncate">{user.username}</Col>
            <Col>{priceToString(user.profile?.cash[process.env.REACT_APP_MARKET_ID as string])}</Col>
            <Col>{priceToString(user.profile?.total_cash)}</Col>
          </Row>)
      }
    </>;
  }
}